import React, { useEffect, useState } from "react";
import {
  NewseLatter,
  Partners,
} from "../../components/component";
import Meta from "../../components/Meta";
import CoverflowCarousel from "../../components/carousel/coverflowCarousel";
import Hero_2 from "../../components/hero/hero_2";

const Home_4 = () => {

  useEffect(() => {
  }, []);

  return (
    <>
      <Meta title="Home" />
      <Hero_2 />
      <CoverflowCarousel />
      <NewseLatter bgWhite={true} />     
      <Partners />
    </>
  );
};

export default Home_4;
