import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, EffectCoverflow, Ally } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { useContext, useEffect, useState } from "react";
import Link from "next/link";
import Image from "next/image";
import LootboxResourcesContext from "../LootboxResourcesContext";

const CoverflowCarousel = () => {
  const { data, refreshData } = useContext(LootboxResourcesContext);
  const [lootboxResources, setLootboxResources] = useState([]);

  useEffect(() => {
    sessionStorage.setItem('lootboxComponentAddress', data?.componentAddress);
    if (data && Array.isArray(data.componentFungiblesItems) && Array.isArray(data.componentNonFungiblesItems)) {   
      const extractFields = (item, index) => {
        const commonFields = {
          name: item.name,
          resource_address: item.resource_address,
          key_image_url: item.icon_url,
          info_url: item.info_url, 
          amount: 'amount' in item ? item.amount : 'N/A'
        };

        if (item.ids && Array.isArray(item.ids)) {
          return item.ids.map(id => ({
            ...commonFields, 
            id: id, 
          }));
        } else {
          return {
            ...commonFields, 
            id: index,
          };
        }
      };

      const combinedItems = [
        ...data.componentFungiblesItems.flatMap(extractFields), 
        ...data.componentNonFungiblesItems.flatMap(extractFields),
      ];

      // randomize combined items
      ;
    
      setLootboxResources(combinedItems.sort(() => Math.random() - 0.5).slice(0, 50).filter(item => item.name !== "Radix"));
    } else if (data) {
      console.error("componentFungibleItems or componentNonFungibleItems is not an array");
    }
  }, [data]); 

  if (!data) {
    return (
      <div className="h-96 flex justify-center items-center animate-pulse text-3xl"> {/* Adjust the height as needed */}
        <svg className="animate-spin -ml-1 mr-3 h-[50px] w-[50px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"> {/* Adjust width and height as needed */}
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
        </svg>
      </div>
    );
  }

  return (
    <>
      {/* <!-- Coverflow Slider --> */}
      <div className="relative px-6 pb-16 sm:px-0">
        {/* <!-- Slider --> */}
        <Swiper
          breakpoints={{
            // when window width is >= 640px
            100: {
              // width: 640,
              slidesPerView: 1,
            },
            575: {
              // width: 640,
              slidesPerView: 3,
            },
            // when window width is >= 768px
            992: {
              // width: 768,
              slidesPerView: 5,
            },
          }}
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={false}
          slidesPerView={5}
          loop={true}
          coverflowEffect={{
            rotate: 30,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={true}
          modules={[EffectCoverflow, Pagination, Navigation]}
          navigation={{
            nextEl: ".swiper-button-next-4",
            prevEl: ".swiper-button-prev-4",
          }}
          className="swiper coverflow-slider !py-5"
        >
          {lootboxResources.map((item) => {
            const { key_image_url, id, name, amount, title, resource_address } = item;
            const itemLink = resource_address  + id?.id?.slice(1,-1) + data.componentAddress;
            return (
              <SwiperSlide key={itemLink}>
                <article>
                  <div className="block overflow-hidden rounded-2.5xl bg-white shadow-md transition-shadow hover:shadow-lg dark:bg-jacarta-700">
                    <figure className="relative">
                      <Link href={"/item/" + itemLink}>
                        <Image
                          width={379}
                          height={430}
                          src={id.key_image_url || key_image_url || "https://assets-global.website-files.com/6053f7fca5bf627283b582c2/6460ea3385d0ef5c36da40ae_icon-fs-radix.png"}
                          alt={title}
                          className="swiper-lazy h-[430px] w-full object-cover"
                        />
                      </Link>
                    </figure>
                    <div className="p-6">
                      <div className="flex">
                        <Link href="#" className="shrink-0">
                          <Image
                            width={40}
                            height={40}
                            src={key_image_url || "https://assets.radixdlt.com/icons/icon-xrd-32x32.png"}
                            alt="avatar"
                            className="mr-4 h-10 w-10 rounded-full"
                          />
                        </Link>
                        <div>
                          <Link href={"/item/" + itemLink} className="block">
                            <span className="font-display text-lg leading-none text-jacarta-700 hover:text-accent dark:text-white">
                              {title}
                            </span>
                          </Link>
                          <Link
                            href="#"
                            className="text-lg text-accent "
                          >
                            <div className="flex flex-col w-full">
                              <div className="w-full flex justify-between gap-16">
                                <div>{name}</div>
                                <div>{amount !== "N/A" ? "Qty: " + amount : "" }</div>
                              </div>
                              <span>{id.id}</span>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
              </SwiperSlide>
            );
          })}
        </Swiper>

        <div className="swiper-button-prev-4 group absolute top-1/2 left-4 z-10 -mt-6 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl shadow-white-volume">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className="fill-jacarta-700 group-hover:fill-accent"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
          </svg>
        </div>
        <div className="swiper-button-next-4 group absolute top-1/2 right-4 z-10 -mt-6 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl shadow-white-volume">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className="fill-jacarta-700 group-hover:fill-accent"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
          </svg>
        </div>

        {/* <!-- end coverflow slider --> */}
      </div>
    </>
  );
};

export default CoverflowCarousel;
