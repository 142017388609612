import ArtsCarousel from "../carousel/artCarousel";
import Link from "next/link";
import Image from "next/image";

const Hero_2 = () => {
  return (
    <>
      {/* <!-- Hero --> */}
      <section className="hero relative py-12 md:pt-12">
        <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 dark:hidden">
          <figure className="h-[630px] w-full">
            <Image
              width={1519}
              height={712}
              className="w-full h-full object-cover"
              src="/images/gradient.jpg"
              alt="gradient"
            />
          </figure>
        </picture>
        <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block">
          <figure className="h-[630px] w-full">
            <Image
              width={1519}
              height={712}
              src="/images/gradient_dark.jpg"
              alt="gradient dark"
              className="w-full h-full object-cover"
            />
          </figure>
        </picture>

        <div className="container">
          <div className="py-24 text-center">
            <h1 className="mb-2 font-display text-xl text-jacarta-700 dark:text-white lg:text-2xl xl:text-3xl">
              Discover, Play & Win<br/>
              <div className="animate-gradient mt-4 -mb-5">Top Radix NFTs</div>
            </h1>
          </div>

          <div className="relative">
            {/* <!-- Slider --> */}
            <ArtsCarousel />
          </div>
        </div>
      </section>
      {/* <!-- end hero --> */}
    </>
  );
};

export default Hero_2;
